import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {NgModule} from '@angular/core'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {HttpModule} from '@angular/http';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router'

import {AppRoutingModule} from './app.routing'
import {ComponentsModule} from './components/components.module'
import {AppComponent} from './app.component'
import {AdminAuthLayoutComponent} from './layouts/admin-auth-layout/admin-auth-layout.component'
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component'
import {PagesLayoutComponent} from './layouts/pages-layout/pages-layout.component'
import {APIService} from './api.service'
import {GlobalService} from './global.service';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpModule,
        HttpClientModule,
        ComponentsModule,
        RouterModule,
        AppRoutingModule
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AdminAuthLayoutComponent,
        PagesLayoutComponent,
    ],
    providers: [APIService, GlobalService],
    bootstrap: [AppComponent]
})
export class AppModule {
}

import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'admin-auth-navbar',
    templateUrl: './admin-auth-navbar.component.html',
    styleUrls: ['./admin-auth-navbar.component.css']
})
export class AdminAuthNavbarComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }


}

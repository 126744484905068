import {Component, OnInit} from '@angular/core';
import 'rxjs/add/operator/filter';

// import { AdminAuthNavbarComponent } from '../../components/admin-auth-navbar/admin-auth-navbar.component'

@Component({
    selector: 'app-pages-layout',
    templateUrl: './pages-layout.component.html',
    styleUrls: ['./pages-layout.component.scss']
})
export class PagesLayoutComponent implements OnInit {


    constructor() {
    }

    ngOnInit() {

    }

}

import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs/Observable'
import {map} from 'rxjs/operators/map'
import {Router} from '@angular/router'
import {serverUrl} from './config'

interface TokenResponse {
    status: string,
    data: {
        token: string
        userId: string
        userType: string
        email: string
        userDetails: string
    }
}

@Injectable()
export class APIService {
    private token: string
    private userId: string
    private userType: string
    private userDetails: string

    constructor(private http: HttpClient, private router: Router) {
    }

// function to get user id from localStorage
    public getUser(): string {
        if (!this.userId) {
            this.userId = this.getKeyFromStorage('userId')
            this.userType = this.getKeyFromStorage('userType')
        }
        return this.userId
    }

// function to get token & userId  from token payload
    public getUserDetails(): any {
        const token = this.getToken()
        const userId = this.getUser()
        let payload
        if (token && userId) {
            payload = token.split('.')[1]
            payload = window.atob(payload)
            return JSON.parse(payload)
        } else {
            return null
        }
    }

// function to check if user is logged in
    public isLoggedIn(): boolean {
        const user = this.getUserDetails()
        if (user) {
            return user.exp > Date.now() / 1000
        } else {
            return false
        }
    }

    // function to make request to server to logout user
    public logout(): void {
        this.token = ''
        this.removeKeyFromStorage('userId')
        this.removeKeyFromStorage('userType')
        this.removeKeyFromStorage('email')
        this.removeKeyFromStorage('userDetails')

        window.localStorage.clear();
        window.sessionStorage.clear();
        this.router.navigate(['/', 'admin-auth', 'login'])
    }

    // function to make request to server
    public apiRequest(method, apiUrl, req_vars: any, baseUrl?: string): Observable<any> {
        const token = localStorage.getItem('token');
        if (token) {
            req_vars.accessToken = token;
        }
        return this.request(method, apiUrl, req_vars, baseUrl);
    }

    public downloadUserList(): void {
        const token = localStorage.getItem('token') || '';
        const userId = localStorage.getItem('userId') || ''
        if (!token || !userId) {
            this.logout()
        }
        this.http.get(serverUrl + `/api/admin/emails-csv`, {headers: {userId: userId, accessToken: token}, responseType: 'blob' as 'json'})
            .subscribe(
                (response: any) => {
                    const dataType = response.type;
                    const binaryData = [];
                    binaryData.push(response);
                    const downloadLink = document.createElement('a');
                    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
                    downloadLink.setAttribute('download', 'users.csv');
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                }
            )
    }

// function to save token and user id
    private saveToken(token: string, userId: string, userType: string, email: string, userDetails: string, req: any): void {
        this.token = token
        this.userId = userId
        this.userType = userType
        if (typeof (userDetails) !== 'string') {
            userDetails = JSON.stringify(userDetails)
        }
        this.userDetails = userDetails
        localStorage.setItem('userId', userId)
        localStorage.setItem('userType', userType)
        localStorage.setItem('email', email)
        localStorage.setItem('userDetails', userDetails)
        const stayLoggedIn = localStorage.getItem('stayLoggedIn') || sessionStorage.getItem('stayLoggedIn')
        if (!stayLoggedIn) {
            localStorage.setItem('stayLoggedIn', 'local')
        }
    }

// function to get token from localStorage
    private getToken(): string {
        if (!this.token) {
            this.token = this.getKeyFromStorage('token')
        }
        return this.token
    }

// function to get key from storages
    private getKeyFromStorage(key): any {
        if (localStorage.getItem(key)) {
            return localStorage.getItem(key)
        } else if (sessionStorage.getItem(key)) {
            return sessionStorage.getItem(key)
        }
        return '';
    }

// function to get key from storages
    private removeKeyFromStorage(key): any {
        if (localStorage.getItem(key)) {
            localStorage.removeItem(key)
        }
        if (sessionStorage.getItem(key)) {
            sessionStorage.removeItem(key)
        }
    }

// function to make request from frontend
    private request(method: 'post' | 'get', type: string, data?: any, baseUrl: string = serverUrl): Observable<any> {
        let base
        const userId = localStorage.getItem('userId') || ''
        if (method === 'post') {
            base = this.http.post(baseUrl + `/api/${type}`, data, {headers: {userId: userId, accessToken: this.getToken()}})
        } else {
            base = this.http.get(baseUrl + `/api/${type}`, {headers: {userId: userId, accessToken: this.getToken()}})
        }

        const request = base.pipe(
            map((response: TokenResponse) => {
                if (response.data && response.data.token) {
                    // check if user type is same
                    if (data.userType === response.data.userType) {
                        const {token, userId: user_id, userType, email, userDetails} = response.data
                        this.saveToken(token, user_id, userType, email, userDetails, data)
                        return response
                    } else {
                        return {status: 'error', data: {message: 'Please check your credentials'}}
                    }
                } else {
                    return response
                }
            })
        )
        return request
    }
}

import {Component, OnInit} from '@angular/core'
import {Router} from '@angular/router'
import {APIService} from '../../api.service'
import {menuItems} from '../../config'
import * as $ from 'jquery'
import {environment} from '../../../environments/environment';

// declare const $: any; // previous devs make this sh#t


@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    menuItems: any[];
    env: string;

    constructor(private router: Router, private api: APIService) {
    }

    ngOnInit() {
        this.env = environment.name;
        this.menuItems = menuItems.filter(menuItem => menuItem);
    }

    isMobileMenu() {
        return $(window).width() <= 991;
    }

    logout() {
        this.api.logout()
        this.router.navigate(['/', 'admin-auth'])
    }
}

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

let serverUrlEnv: string

// localhost
if (window.location.hostname.indexOf('localhost') > -1) {
    serverUrlEnv = 'http://localhost:3000'
} else {  // server
    serverUrlEnv = window.location.origin
}
export const s3Url = 'https://s3.amazonaws.com/docuchef/receipe/' // todo: make it diff for each env (prod, stage..)
export const snackbarTime = 3000
export const commonErrMsg = 'Something went wrong. Please try again!'
export const serverUrl = serverUrlEnv
export const menuItems: RouteInfo[] = [
    {path: '/admin/dashboard', title: 'Dashboard', icon: 'dashboard', class: ''},
    {path: '/admin/ingredients', title: 'Ingredient List', icon: 'fastfood', class: ''},
    {path: '/admin/units', title: 'Unit List', icon: 'format_list_numbered', class: ''},
    {path: '/admin/commands', title: 'Command List', icon: 'assignment', class: ''},
    {path: '/admin/preset', title: 'Manage Preset Recipe', icon: 'build_circle', class: ''},
    {path: '/admin/new-ingredients', title: 'New Ingredients', icon: 'fastfood', class: ''},
    {path: '/admin/notifications', title: 'Notifications List', icon: 'assignment', class: ''},
]
export const commandRuleOperator = [
    {value: 'equals', label: 'Equals to'},
    {value: 'includes', label: 'Includes'},
    {value: 'begin', label: 'Begin with'},
    {value: 'andend', label: 'End with'},
    {value: 'orend', label: 'Or End with'},
]
export const commandGroups = [
    'To ask MyKA to Go To recipe',
    'To ask MyKA to Go to Search',
    'To ask MyKA to Go to profile',
    'Edit Recipe',
    'To ask MyKA to Edit Recipe details',
    'To ask MyKA to Edit ingredients, quantities',
    'To ask MyKA to Go to next step in Start Cooking',
    'Search Recipe',
    'To ask MyKA to Search for cooking method',
    'To ask MyKA to Search for recipe title/ingredients',
    'To ask MyKA to Sorting recipes',
    'Create Recipe',
    'To ask MyKA to Enter Recipe details',
    'To ask MyKA to Skip above details',
    'To ask MyKA to register Step 1 - n',
    'To ask MyKA to Edit in preview',
    'To ask MyKA to Add notes',
    'To ask MyKA to Edit notes',
    'To ask MyKA to delete a recipe',
    'To ask MyKA to Go to recipe',
    'To ask MyKA to Start cooking',
    'To ask MyKA to Go to next step',
    'To ask MyKA to Repeat step',
    'To ask MyKA to Go to previous step',
    'To ask MyKA to Finish',
    'To ask MyKA to Pause cooking',
    'To ask MyKA to Restart cooking',
    'To ask MyKA to Restart cooking from the beginning',
    'To ask MyKA to Restart cooking from where the end user left',
    'To filter the recipes on landing screen',
    'To sort the above filtered recipes',
    'Wake-up MyKA',
    'To ask MyKA to delete notes',
    'To tell Myka to continue with creating the recipe',
    'Save step/recipe',
    'To ask MyKA to delete step'
]

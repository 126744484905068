import {NgModule} from '@angular/core';
import {CommonModule,} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';

import {AdminAuthLayoutComponent} from './layouts/admin-auth-layout/admin-auth-layout.component';
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {PagesLayoutComponent} from './layouts/pages-layout/pages-layout.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'admin-auth/login',
        pathMatch: 'full',
    }, {
        path: 'pages',
        component: PagesLayoutComponent,
        children: [{
            path: '',
            loadChildren: './layouts/pages-layout/pages-layout.module#PagesLayoutModule'
        }]
    }, {
        path: 'admin-auth',
        component: AdminAuthLayoutComponent,
        children: [{
            path: '',
            loadChildren: './layouts/admin-auth-layout/admin-auth-layout.module#AdminAuthLayoutModule'
        }]
    }, {
        path: 'admin',
        component: AdminLayoutComponent,
        children: [{
            path: '',
            loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
        }]
    }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, {
            useHash: true
        })
    ],
    exports: [],
})
export class AppRoutingModule {
}

import {Component, OnInit} from '@angular/core';
import 'rxjs/add/operator/filter';

@Component({
    selector: 'app-admin-auth-layout',
    templateUrl: './admin-auth-layout.component.html',
    styleUrls: ['./admin-auth-layout.component.scss']
})
export class AdminAuthLayoutComponent implements OnInit {


    constructor() {
    }

    ngOnInit() {

    }

}

import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {
    email: string

    constructor() {
    }

    setEmail(email) {
        this.email = email
    }

    getEmail() {
        return this.email
    }
}
